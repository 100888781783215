import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import "./coming-soon.css"

const ComingSoon = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "ventures-benchmark-labs-logo.webp" }) {
        childImageSharp {
          gatsbyImageData(width: 600, placeholder: BLURRED)
        }
      }
    }
  `)

  const logoImage = getImage(data.file.childImageSharp.gatsbyImageData)

  return (
    <div className="coming-soon-container">
      <GatsbyImage
        image={logoImage}
        alt="Benchmark Labs Logo"
        className="coming-soon-logo"
      />
      <div className="coming-soon-text">Coming Soon</div>
    </div>
  )
}

export default ComingSoon
